import React, { useState, ReactNode, useEffect } from 'react'
import { CurrencyOptions } from '../../../shared/ui/currency'
import { Formik, Field, Form, FieldArray } from 'formik'
import { useRootStoreSelector } from '../../../shared/store/hooks/useRootStoreSelector'
import { useHistory, Link } from 'react-router-dom'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import { CompanySettingsModel, CompanyType } from '../../../shared/models'
import { toastSuccess, toastWarning } from '../../../shared/toastr'
import * as Yup from 'yup'
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage'
import { OfferionTooltip } from '../../../shared/tooltip'
import { NumberField } from '../../../shared/forms/NumberField'
import { useDispatch } from 'react-redux'
import { UpdateLanguage } from '../../../shared/store/actions/userActions'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { CollapsibleSection } from '../../ui/CollapsibleSection'
import { Checkbox } from '../../ui/Checkbox'

const ComponentWithShowInPrintCheckbox = ({ checkboxName, children }: { checkboxName: string; children: ReactNode }) => {
    const { t } = useOfferionTranslation()

    return (
        <div className="info__row-content info__row-content--with-button">
            {children}

            <Checkbox name={checkboxName} label={t('Settings.Table.All.buttonShowPrint')} hideLabelOnSmallerScreens mobileIcon="print" />
        </div>
    )
}

export const CompanySettingsContainer = () => {
    const { t } = useOfferionTranslation()
    const history = useHistory()
    const companyType = useRootStoreSelector(s => s.user.CompanyType)
    const [settings, setSettings] = useState<CompanySettingsModel | null>(null)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        CompanySettingsApi.get().then(setSettings)
    }, [])

    if (!settings) return null

    function toggleShowCompanyAddressInPrint(formikProps: any) {
        formikProps.setFieldValue('showCompanyAddress-checkbox', !formikProps.values['showCompanyAddress-checkbox'])
    }

    const onSubmit = async (newSettings: CompanySettingsModel) => {
        await CompanySettingsApi.update(newSettings)

        dispatch(UpdateLanguage(newSettings.defaultUiLanguage))

        toastSuccess('Settings.Save.Message.successUpdate')

        toastWarning('Settings.Save.Message.successUpdateNote')
    }

    const schema = Yup.object().shape({
        name: Yup.string().required(t('Allaround.Validation.required', { fieldName: t('Settings.Table.BasicInformation.name') })),
        oib: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.Table.BasicInformation.identificationNumber') }))
            .max(
                11,
                t('Allaround.Validation.exactLength', {
                    fieldName: t('Settings.Table.BasicInformation.identificationNumber'),
                    exactLength: 11,
                })
            )
            .min(
                11,
                t('Allaround.Validation.exactLength', {
                    fieldName: t('Settings.Table.BasicInformation.identificationNumber'),
                    exactLength: 11,
                })
            ),
        street: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.Table.Address.street') }))
            .nullable(),
        streetNumber: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.Table.Address.streetNumber') }))
            .max(10, t('Allaround.Validation.maxLength', { fieldName: t('Settings.Table.Address.streetNumber'), maxLength: 10 }))
            .nullable(),
        city: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.Table.Address.city') }))
            .nullable(),
        postalCode: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.Table.Address.postNumber') }))
            .max(10)
            .nullable(),
        contactEmail: Yup.string()
            .nullable()
            .email(t('Allaround.Validation.mail', { fieldName: t('Settings.Table.Contact.email') })),
    })

    return (
        <>
            <main className="main">
                <Formik validationSchema={schema} initialValues={settings} onSubmit={onSubmit}>
                    {formikProps => {
                        return (
                            <Form noValidate={true}>
                                <div className="main__header">
                                    <div className="main-title">{t('Settings.Header.pageTitle')}</div>

                                    <div className="main__header-buttons">
                                        <div className="button-outer">
                                            <button type="submit" className="button button--white button--padded">
                                                <span className="icon icon__save"></span>
                                                <span className="button-text">{t('Allaround.Button.save')}</span>
                                            </button>
                                        </div>
                                        <div className="button-outer">
                                            <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                                <span className="icon icon__delete"></span>
                                                <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                            </button>
                                        </div>

                                        <div className="button-outer overlay__item overlay__item--bottom-left less-than-wide-only">
                                            {!isMenuVisible && (
                                                <button
                                                    onClick={() => setIsMenuVisible(true)}
                                                    type="button"
                                                    className="button button--gray overlay__button">
                                                    <span className="icon icon__menu-blue"></span>
                                                </button>
                                            )}

                                            {isMenuVisible && (
                                                <button
                                                    className="button button--gray overlay__button overlay__button--active"
                                                    type="button"
                                                    onClick={() => setIsMenuVisible(false)}>
                                                    <span className="icon icon__menu-blue"></span>
                                                </button>
                                            )}

                                            <div className="overlay" ng-show="vm.showImportExportMenu">
                                                <ul className="overlay__list">
                                                    <li>
                                                        <Link to="/settings/users">
                                                            <span>{t('Settings.Side.users')}</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/settings/print">
                                                            <span>{t('Settings.Side.printerSettings')}</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/settings/pictures">
                                                            <span>{t('Settings.Side.myPictures')}</span>
                                                        </Link>
                                                    </li>
                                                    {settings.isFiscalizationModuleActive && (
                                                        <li>
                                                            <Link to="/settings/fiscalization">
                                                                <span>{t('Settings.Side.fiscalisation')}</span>
                                                            </Link>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <Link to="/settings/sale-places">
                                                            <span>{t('Settings.Side.salesPlaces')}</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/settings/e-invoice">
                                                            <span>{t('Settings.Side.eInvoice')}</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="search-form simple-search">
                                    <h2 className="search__title">{t('Settings.Side.title')}</h2>

                                    <div className="search__content">
                                        <Link
                                            className="search-form__button search-form__button--full-width button button--white"
                                            to="/settings/users">
                                            <span className="button-text button-text--left">{t('Settings.Side.users')}</span>
                                            <span className="icon icon__right icon--small"></span>
                                        </Link>
                                        <Link
                                            className="search-form__button search-form__button--full-width button button--white"
                                            to="/settings/print">
                                            <span className="button-text button-text--left">{t('Settings.Side.printerSettings')}</span>
                                            <span className="icon icon__right icon--small"></span>
                                        </Link>
                                        <Link
                                            className="search-form__button search-form__button--full-width button button--white"
                                            to="/settings/pictures">
                                            <span className="button-text button-text--left">{t('Settings.Side.myPictures')}</span>
                                            <span className="icon icon__right icon--small"></span>
                                        </Link>
                                        {settings.isFiscalizationModuleActive && (
                                            <Link
                                                className="search-form__button search-form__button--full-width button button--white"
                                                to="/settings/fiscalization">
                                                <span className="button-text button-text--left">{t('Settings.Side.fiscalisation')}</span>
                                                <span className="icon icon__right icon--small"></span>
                                            </Link>
                                        )}
                                        <Link
                                            className="search-form__button search-form__button--full-width button button--white"
                                            to="/settings/sale-places">
                                            <span className="button-text button-text--left">{t('Settings.Side.salesPlaces')}</span>
                                            <span className="icon icon__right icon--small"></span>
                                        </Link>
                                        <Link
                                            className="search-form__button search-form__button--full-width button button--white"
                                            to="/settings/e-invoice">
                                            <span className="button-text button-text--left">{t('Settings.Side.eInvoice')}</span>
                                            <span className="icon icon__right icon--small"></span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="invoices__container curled-paper settings">
                                    <h1 className="invoices__title invoices__title--short">{t('Settings.Table.BasicInformation.title')}</h1>

                                    <div className="table__outer">
                                        <div className="info__container">
                                            <div className="info__row">
                                                <label className="info__row-name" htmlFor="Title">
                                                    {t('Settings.Table.BasicInformation.name')}*
                                                </label>
                                                <ComponentWithShowInPrintCheckbox checkboxName="showCompanyName">
                                                    <span className="input__info-icon-holder input__wrapper--icon-inside overlay__item--right-middle">
                                                        <Field
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            placeholder={t('Settings.Table.BasicInformation.namePlaceholder')}
                                                            required
                                                        />
                                                        <OfferionTooltip
                                                            titleKey="Settings.Table.BasicInformation.nameInfoTitle"
                                                            bodyKey="Settings.Table.BasicInformation.nameInfo"
                                                        />
                                                        <OfferionErrorMessage name="name" />
                                                    </span>
                                                </ComponentWithShowInPrintCheckbox>
                                            </div>

                                            <div className="info__row">
                                                <label className="info__row-name" htmlFor="PersonalNumber">
                                                    {t('Settings.Table.BasicInformation.identificationNumber')} *
                                                </label>

                                                <ComponentWithShowInPrintCheckbox checkboxName="showCompanyOib">
                                                    {settings.isFiscalizationActive ? (
                                                        <>
                                                            <span className="info__row-name">
                                                                {settings.oib}
                                                                <OfferionTooltip
                                                                    iconStyle={{ marginLeft: '8px' }}
                                                                    placement="right"
                                                                    titleKey="Settings.Table.BasicInformation.identificationNumber.fiscalizationIsOnInfoTitle"
                                                                    bodyKey="Settings.Table.BasicInformation.identificationNumber.fiscalizationIsOnInfo"
                                                                />
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <Field
                                                            className="info__input--with-button"
                                                            type="text"
                                                            placeholder={t(
                                                                'Settings.Table.BasicInformation.identificationNumberPlaceholder'
                                                            )}
                                                            name="oib"
                                                        />
                                                    )}
                                                </ComponentWithShowInPrintCheckbox>
                                                <OfferionErrorMessage name="oib" />
                                            </div>
                                        </div>
                                    </div>

                                    <CollapsibleSection
                                        title={<span>{t('Settings.Table.Address.title')}</span>}
                                        additionalButtonsComponent={() => (
                                            <Checkbox
                                                name="showCompanyAddress"
                                                label={t('Settings.Table.All.buttonShowPrint')}
                                                hideLabelOnSmallerScreens
                                                mobileIcon="print"
                                            />
                                        )}>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Address.street')}*
                                            </label>
                                            <div className="info__row-content info__row-content--with-button">
                                                <div className="">
                                                    <Field
                                                        name="street"
                                                        id="street"
                                                        type="text"
                                                        placeholder={t('Settings.Table.Address.streetPlaceholder')}
                                                    />
                                                </div>
                                            </div>
                                            <OfferionErrorMessage name="street" />
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Address.streetNumber')}*
                                            </label>
                                            <div className="info__row-content info__row-content--with-button">
                                                <Field
                                                    name="streetNumber"
                                                    id="streetNumber"
                                                    type="text"
                                                    placeholder={t('Settings.Table.Address.streetNumberPlaceholder')}
                                                />
                                            </div>
                                            <OfferionErrorMessage name="streetNumber" />
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Address.city')}*
                                            </label>
                                            <div className="info__row-content info__row-content--with-button">
                                                <div className="">
                                                    <Field
                                                        id="city"
                                                        name="city"
                                                        type="text"
                                                        placeholder={t('Settings.Table.Address.cityPlaceholder')}
                                                    />
                                                </div>
                                            </div>
                                            <OfferionErrorMessage name="city" />
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Address.postNumber')}*
                                            </label>
                                            <div className="info__row-content info__row-content--with-button">
                                                <Field
                                                    type="text"
                                                    placeholder={t('Settings.Table.Address.postNumberPlaceholder')}
                                                    name="postalCode"
                                                />
                                            </div>
                                            <OfferionErrorMessage name="postalCode" />
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="insertSalePlaceCounty">
                                                {t('Settings.Table.Address.county')}
                                            </label>
                                            <div className="info__row-content info__row-content--with-button">
                                                <Field
                                                    id="insertSalePlaceCounty"
                                                    type="text"
                                                    name="addressArea"
                                                    placeholder={t('Settings.Table.Address.countyPlaceholder')}
                                                />
                                            </div>
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Address.country')}
                                            </label>
                                            <div className="info__row-content info__row-content--with-button">
                                                <div className="">
                                                    <Field type="text" name="country" />
                                                </div>
                                            </div>
                                        </div>
                                    </CollapsibleSection>

                                    <CollapsibleSection title={<span>{t('Settings.Table.Contact.title')}</span>}>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Contact.phone')}
                                            </label>
                                            <ComponentWithShowInPrintCheckbox checkboxName="showCompanyTelephone">
                                                <Field className="info__input--with-button" type="text" name="telephone" />
                                            </ComponentWithShowInPrintCheckbox>
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Contact.mobile')}
                                            </label>
                                            <ComponentWithShowInPrintCheckbox checkboxName="showCompanyMobile">
                                                <Field className="info__input--with-button" type="text" name="mobile" />
                                            </ComponentWithShowInPrintCheckbox>
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Contact.fax')}
                                            </label>
                                            <ComponentWithShowInPrintCheckbox checkboxName="showCompanyFax">
                                                <Field className="info__input--with-button" type="text" name="fax" />
                                            </ComponentWithShowInPrintCheckbox>
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Contact.email')}
                                            </label>

                                            <ComponentWithShowInPrintCheckbox checkboxName="showCompanyEmail">
                                                <span className="input__info-icon-holder input__wrapper--icon-inside info__input--with-button">
                                                    <Field name="contactEmail" type="email" />
                                                    <OfferionTooltip
                                                        titleKey="Settings.Table.Contact.emailInfoTitle"
                                                        bodyKey="Settings.Table.Contact.emailInfo"
                                                    />
                                                    <OfferionErrorMessage name="contactEmail" />
                                                </span>
                                            </ComponentWithShowInPrintCheckbox>
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Contact.web')}
                                            </label>
                                            <ComponentWithShowInPrintCheckbox checkboxName="showCompanyWebSite">
                                                <Field className="info__input--with-button" type="text" name="website" />
                                            </ComponentWithShowInPrintCheckbox>
                                        </div>
                                        <div className="info__row">
                                            <label className="info__row-name" htmlFor="">
                                                {t('Settings.Table.Contact.iban')}
                                            </label>
                                            <ComponentWithShowInPrintCheckbox checkboxName="showCompanyBankAccount">
                                                <Field className="info__input--with-button" type="text" name="bankAccount" />
                                            </ComponentWithShowInPrintCheckbox>
                                        </div>
                                    </CollapsibleSection>

                                    <CollapsibleSection title={<span>{t('Settings.Table.TaxCurrency.title')}</span>}>
                                        <div className="info__row info__row--split">
                                            <span>
                                                <label htmlFor="isAccountingVat">{t('Settings.Table.TaxCurrency.companyInVAT')}</label>
                                            </span>
                                            <Checkbox name="isAccountingVat" />
                                        </div>

                                        {formikProps.values.isAccountingVat && (
                                            <div className="info__row info__row--split">
                                                <span>
                                                    <label htmlFor="canChangeVat">
                                                        {t('Settings.Table.TaxCurrency.changeVATperProduct')}
                                                    </label>

                                                    <OfferionTooltip
                                                        placement="right"
                                                        titleKey="Settings.Table.TaxCurrency.useTotalPriceInfoTitle"
                                                        bodyKey="Settings.Table.TaxCurrency.useTotalPriceInfo"
                                                    />
                                                </span>

                                                <Checkbox name="canChangeVat" />
                                            </div>
                                        )}

                                        {formikProps.values.isAccountingVat && (
                                            <div className="info__row">
                                                <label className="info__row-name">
                                                    {t('Settings.Table.TaxCurrency.defaultVAT')}
                                                    <OfferionTooltip
                                                        titleKey="Settings.Table.TaxCurrency.defaultVATInfoTitle"
                                                        bodyKey="Settings.Table.TaxCurrency.defaultVATInfo"
                                                    />
                                                </label>

                                                <div className="info__row-content">
                                                    <Field component={NumberField} className="width--60-p" name="defaultVat" />
                                                    <span className="text-color mod-margin-left-4">%</span>
                                                </div>
                                            </div>
                                        )}

                                        <div className="info__row">
                                            <label className="info__row-name">
                                                {t('Settings.Table.TaxCurrency.predefinedCurrency')}

                                                <OfferionTooltip
                                                    placement="right"
                                                    titleKey="Settings.Table.TaxCurrency.predefinedCurrencyInfoTitle"
                                                    bodyKey="Settings.Table.TaxCurrency.predefinedCurrencyInfo"
                                                />
                                            </label>
                                            <div className="info__row-content">
                                                <div className="info__row-content select-container">
                                                    <Field as="select" className="select" name="defaultCurrency">
                                                        <CurrencyOptions />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info__row info__row--split">
                                            <span>
                                                <label htmlFor="hasMultipleValutes">
                                                    {t('Settings.Table.TaxCurrency.useMoreCurrencies')}
                                                </label>
                                            </span>
                                            <Checkbox name="hasMultipleValutes" />
                                        </div>

                                        {formikProps.values.hasMultipleValutes && (
                                            <>
                                                {' '}
                                                <div className="info__row">
                                                    <label className="info__row-name">
                                                        {t('Settings.Table.TaxCurrency.predefinedOfferCurrency')}
                                                    </label>
                                                    <div className="info__row-content">
                                                        <div className="info__row-content select-container">
                                                            <Field as="select" className="select" name="defaultTenderCurrency">
                                                                <CurrencyOptions />
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info__row">
                                                    <label className="info__row-name">
                                                        {t('Settings.Table.TaxCurrency.predefinedInvoiceCurrency')}
                                                    </label>
                                                    <div className="info__row-content">
                                                        <div className="info__row-content select-container">
                                                            <Field as="select" className="select" name="defaultReceiptCurrency">
                                                                <CurrencyOptions />
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info__row">
                                                    <label className="info__row-name">
                                                        {t('Settings.Table.TaxCurrency.predefinedClientCurrency')}
                                                    </label>
                                                    <div className="info__row-content">
                                                        <div className="info__row-content select-container">
                                                            <Field as="select" className="select" name="defaultClientCurrency">
                                                                <CurrencyOptions />
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </CollapsibleSection>

                                    <CollapsibleSection title={<span>{t('Settings.Table.Pricelist.title')}</span>}>
                                        <div className="info__row info__row--split">
                                            <span>
                                                <label htmlFor="enterFullPriceEnabled">{t('Settings.Table.Pricelist.useTotalPrice')}</label>
                                                <OfferionTooltip
                                                    placement="right"
                                                    titleKey="Settings.Table.Pricelist.useTotalPriceInfoTitle"
                                                    bodyKey="Settings.Table.Pricelist.useTotalPriceInfo"
                                                />
                                            </span>
                                            <Checkbox name="enterFullPriceEnabled" />
                                        </div>

                                        {companyType == CompanyType.WindowMaker ? (
                                            <div className="info__row">
                                                <Field id="show-prime-cost2" type="checkbox" name="showProductDimensionsInfo" />

                                                <label htmlFor="show-prime-cost2" className="button button--gray checkbox-button">
                                                    <span className="checkbox-button__icon"></span>
                                                </label>
                                                <label htmlFor="show-prime-cost2">
                                                    {t('Settings.Table.Pricelist.showProductDimensions')}
                                                    <OfferionTooltip
                                                        placement="bottom"
                                                        titleKey="Settings.Table.Pricelist.showProductDimensionsInfoTitle"
                                                        bodyKey="Settings.Table.Pricelist.showProductDimensionsInfo"
                                                    />
                                                </label>
                                            </div>
                                        ) : null}

                                        <div className="info__row info__row--split">
                                            <span>
                                                <label htmlFor="editPurchasePrice">{t('Settings.Table.Pricelist.showPurchasePrice')}</label>
                                                <OfferionTooltip
                                                    placement="bottom"
                                                    titleKey="Settings.Table.Pricelist.showPurchasePriceInfoTitle"
                                                    bodyKey="Settings.Table.Pricelist.showPurchasePriceInfo"
                                                />
                                            </span>
                                            <Checkbox name="editPurchasePrice" />
                                        </div>

                                        <div className="info__row info__row--split">
                                            <span>
                                                <label htmlFor="hideGroups">{t('Settings.Table.Pricelist.showGroupsInPrint')}</label>
                                                <OfferionTooltip
                                                    placement="right"
                                                    titleKey="Settings.Table.Pricelist.showGroupsInPrintInfoTitle"
                                                    bodyKey="Settings.Table.Pricelist.showGroupsInPrintInfo"
                                                />
                                            </span>
                                            <Checkbox name="hideGroups" />
                                        </div>

                                        <div className="info__row">
                                            <label className="left settings__left-label" htmlFor="">
                                                {t('Settings.Table.Pricelist.predefinedMeasurementsUnits')}
                                            </label>

                                            {
                                                <FieldArray name="units">
                                                    {arrayHelper => {
                                                        return (
                                                            <>
                                                                {formikProps.values.units.map((unit, index) => {
                                                                    return (
                                                                        <div className="left input__wrapper--icon-inside">
                                                                            <Field
                                                                                type="text"
                                                                                className="input__unit"
                                                                                name={`units.${index}.name`}
                                                                            />
                                                                            <span
                                                                                className="icon icon--small icon--clickable icon__cross-rounded"
                                                                                onClick={() => arrayHelper.remove(index)}></span>
                                                                        </div>
                                                                    )
                                                                })}

                                                                <button
                                                                    className="button button--gray button--plus"
                                                                    type="button"
                                                                    onClick={() => arrayHelper.push({ name: '' })}>
                                                                    <span className="icon icon__plus"></span>
                                                                </button>
                                                            </>
                                                        )
                                                    }}
                                                </FieldArray>
                                            }
                                        </div>
                                    </CollapsibleSection>

                                    <CollapsibleSection title={<span>{t('Settings.Table.Languages.title')}</span>}>
                                        <div className="info__row">
                                            <label className="info__row-name">{t('Settings.Table.Languages.interfaceLanguage')}</label>
                                            <div className="info__row-content">
                                                <div className="info__row-content select-container">
                                                    <Field as="select" className="select" name="defaultUiLanguage">
                                                        <option value="hr">{t('Allaround.Language.Interface.hr-hr')}</option>
                                                        <option value="en">{t('Allaround.Language.Interface.en-en')}</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info__row">
                                            <label className="info__row-name">{t('Settings.Table.Languages.offerLanguage')}</label>
                                            <div className="info__row-content">
                                                <div className="info__row-content select-container">
                                                    <Field as="select" className="select" name="defaultTenderLanguageId">
                                                        <option value="">{t('Allaround.Language.InvoiceOrOffer.printSettings')}</option>
                                                        <option value="cs-cz">{t('Allaround.Language.InvoiceOrOffer.cs-cz')}</option>
                                                        <option value="de-de">{t('Allaround.Language.InvoiceOrOffer.de-de')}</option>
                                                        <option value="en-gb">{t('Allaround.Language.InvoiceOrOffer.en-gb')}</option>
                                                        <option value="es-es">{t('Allaround.Language.InvoiceOrOffer.es-es')}</option>
                                                        <option value="fr-fr">{t('Allaround.Language.InvoiceOrOffer.fr-fr')}</option>
                                                        <option value="hr-hr">{t('Allaround.Language.InvoiceOrOffer.hr-hr')}</option>
                                                        <option value="hu-hu">{t('Allaround.Language.InvoiceOrOffer.hu-hu')}</option>
                                                        <option value="it-it">{t('Allaround.Language.InvoiceOrOffer.it-it')}</option>
                                                        <option value="pl-pl">{t('Allaround.Language.InvoiceOrOffer.pl-pl')}</option>
                                                        <option value="sr-Latn">{t('Allaround.Language.InvoiceOrOffer.sr-Latn')}</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </CollapsibleSection>

                                    <CollapsibleSection title={<span>{t('Settings.Table.PredefinedText.title')}</span>}>
                                        <div className="notice__container notice__container--no-margin">
                                            <h2 className="notice__title">{t('Settings.Table.PredefinedText.invoiceNote')}</h2>
                                            <Field as="textarea" name="defaultReceiptPaymentTerms" className="notice" rows={6} />
                                        </div>

                                        <div className="notice__container notice__container--no-margin">
                                            <h2 className="notice__title">{t('Settings.Table.PredefinedText.offerNote')}</h2>
                                            <Field as="textarea" name="defaultTenderNote" className="notice" rows={6} />
                                        </div>

                                        <div className="notice__container notice__container--no-margin">
                                            <h2 className="notice__title">{t('Settings.Table.PredefinedText.mailOffer')}</h2>
                                            <Field as="textarea" name="defaultMailTenderText" className="notice" rows={6} />
                                        </div>

                                        <div className="notice__container notice__container--no-margin">
                                            <h2 className="notice__title">{t('Settings.Table.PredefinedText.mailInvoice')}</h2>
                                            <Field as="textarea" name="defaultMailReceiptText" className="notice" rows={6} />
                                        </div>
                                    </CollapsibleSection>
                                </div>

                                <div className="main__footer">
                                    <div className="main__footer-buttons main__footer-buttons--mobile-only">
                                        <div className="button-outer">
                                            <button type="submit" className="button button--white button--padded">
                                                <span className="icon icon__save"></span>
                                                <span className="button-text">{t('Allaround.Button.save')}</span>
                                            </button>
                                        </div>
                                        <div className="button-outer">
                                            <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                                <span className="icon icon__delete"></span>
                                                <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </main>
        </>
    )
}
