import { Field, Form, Formik } from 'formik'
import React, { ReactNode, useEffect, useState } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage'
import { toastSuccess, toastWarning } from '../../../shared/toastr'
import { OfferionTooltip } from '../../../shared/tooltip'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import { UpdateEInvoiceConfigurationResult } from '../../../shared/models'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { CollapsibleSection } from '../../ui/CollapsibleSection'

const Section = ({ sectionTitleKey, children, defaultOpen }: { sectionTitleKey: string; children: ReactNode; defaultOpen?: boolean }) => {
    const { t } = useOfferionTranslation()
    const [isCollapsed, setIsCollapsed] = useState(!defaultOpen)

    return (
        <>
            <div className="invoices__title invoices__title--short  invoices__title--with-button">
                <h1 style={{ display: 'inline', cursor: 'pointer' }} onClick={() => setIsCollapsed(!isCollapsed)}>
                    {t(sectionTitleKey)}
                </h1>
                {sectionTitleKey == 'Settings.EInvoice.Table.MyEInvoice.title' && (
                    <OfferionTooltip
                        iconStyle={{ marginBottom: '7px' }}
                        titleKey="Settings.EInvoice.Table.MyEInvoice.Title.infoTitle"
                        bodyKey="Settings.EInvoice.Table.MyEInvoice.infoText"
                    />
                )}
                <button type="button" className="button button--gray right" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <span className={`${isCollapsed ? 'icon icon__down-blue' : 'icon icon__up'}`}></span>
                </button>
            </div>

            <div className={isCollapsed ? 'table__outer info__additional info__additional--hide' : 'table__outer info__additional'}>
                <div className="info__container">{children}</div>
            </div>
        </>
    )
}

export const EInvoiceSettingsContainer = () => {
    const { t } = useOfferionTranslation()
    const history = useHistory()

    const [configuration, setConfiguration] = useState<{
        eReceiptUserName: string
        legalCompanyName: string
        eReceiptPassword: string
    } | null>()

    useEffect(() => {
        CompanySettingsApi.GetEInvoiceConfiguration().then(result => {
            setConfiguration({ ...result, eReceiptPassword: '' })
        })
    }, [])

    const saveConfiguration = (model: { eReceiptUserName: string; legalCompanyName: string; eReceiptPassword: string }) => {
        CompanySettingsApi.SaveEInvoiceConfiguration(model).then(result => {
            switch (result) {
                case UpdateEInvoiceConfigurationResult.Success:
                    toastSuccess('Settings.EInvoice.Messages.successSave')
                    break

                case UpdateEInvoiceConfigurationResult.WrongLoginInformation:
                    toastWarning('Settings.EInvoice.Messages.errorSave')
                    break

                case UpdateEInvoiceConfigurationResult.CompanyMissingOib:
                case UpdateEInvoiceConfigurationResult.CompanyMissingCountry:
                    toastWarning('Settings.EInvoice.Messages.warningMissingCompanyData')

                    break
            }
        })
    }

    if (!configuration) return null

    const schema = Yup.object().shape({
        eReceiptUserName: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.EInvoice.Table.userID') }))
            .nullable(),
        legalCompanyName: Yup.string()
            .required(t('Allaround.Validation.required', { fieldName: t('Settings.EInvoice.Table.obligatoryInvoiceData') }))
            .nullable(),
        eReceiptPassword: Yup.string().when('eReceiptUserName', (eReceiptUserName: string, schema: Yup.StringSchema) => {
            if (eReceiptUserName != configuration.eReceiptUserName)
                return schema.required(t('Allaround.Validation.required', { fieldName: t('Settings.EInvoice.Table.password') }))

            return schema.notRequired()
        }),
    })

    return (
        <main className="main">
            <BreadcrumbsItem to="/e-invoice">{t('Settings.EInvoice.Header.pageTitle')}</BreadcrumbsItem>

            <Formik validationSchema={schema} onSubmit={saveConfiguration} initialValues={configuration}>
                <Form noValidate={true}>
                    <div className="main__header">
                        <div className="main-title">{t('Settings.EInvoice.Header.pageTitle')}</div>

                        <div className="main__header-buttons main__header-buttons--show-all">
                            <div className="button-outer">
                                <button className="button button--white button--padded" type="submit">
                                    <span className="icon icon__save"></span>
                                    <span className="button-text">{t('Allaround.Button.save')}</span>
                                </button>
                            </div>
                            <div className="button-outer">
                                <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                    <span className="icon icon__delete"></span>
                                    <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="table__container curled-paper settings pt3">
                        <CollapsibleSection title={<span>{t('Settings.EInvoice.Table.ObligatoryData.title')}</span>} isOpenByDefault={true}>
                            <div className="info__row">
                                <label style={{ wordWrap: 'break-word' }} className="info__row-name mod-padding-right-6" htmlFor="">
                                    {t('Settings.EInvoice.Table.obligatoryInvoiceData')} *
                                    <OfferionTooltip
                                        iconStyle={{ marginBottom: '7px' }}
                                        titleKey="Settings.EInvoice.Table.ObligatoryInvoiceData.infoTitle"
                                        bodyKey="Settings.EInvoice.Table.ObligatoryInvoiceData.infoText"
                                    />
                                </label>
                                <div className="info__row-content info__row-content--with-button">
                                    <Field style={{ width: '100%' }} as="textarea" name="legalCompanyName" rows={6} />
                                    <OfferionErrorMessage name="legalCompanyName" />
                                </div>
                            </div>
                        </CollapsibleSection>

                        <CollapsibleSection title={<span>{t('Settings.EInvoice.Table.AdditionalData.title')}</span>}>
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">
                                    {t('Settings.EInvoice.Table.AdditionalIBAN')}
                                    <OfferionTooltip
                                        iconStyle={{ marginBottom: '7px' }}
                                        titleKey="Settings.EInvoice.Table.AdditionalIBAN.infoTitle"
                                        bodyKey="Settings.EInvoice.Table.AdditionalIBAN.infoText"
                                    />
                                </label>
                                <div className="info__row-content info__row-content--with-button">
                                    <Field name="secondBankAccount" type="text" />
                                </div>
                                <OfferionErrorMessage name="secondBankAccount" />
                            </div>
                        </CollapsibleSection>

                        <CollapsibleSection
                            title={
                                <div>
                                    <span>{t('Settings.EInvoice.Table.MyEInvoice.title')}</span>
                                    <OfferionTooltip
                                        iconStyle={{ marginBottom: '7px' }}
                                        titleKey="Settings.EInvoice.Table.MyEInvoice.Title.infoTitle"
                                        bodyKey="Settings.EInvoice.Table.MyEInvoice.infoText"
                                    />
                                </div>
                            }>
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">
                                    {t('Settings.EInvoice.Table.userID')} *
                                </label>
                                <div className="info__row-content info__row-content--with-button">
                                    <Field name="eReceiptUserName" type="text" />
                                </div>
                                <OfferionErrorMessage name="eReceiptUserName" />
                            </div>
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">
                                    {t('Settings.EInvoice.Table.password')}
                                </label>
                                <div className="info__row-content info__row-content--with-button">
                                    <Field name="eReceiptPassword" type="text" />
                                </div>
                                <OfferionErrorMessage name="eReceiptPassword" />
                            </div>
                        </CollapsibleSection>
                    </div>
                    <div className="main__footer">
                        <div className="main__footer-buttons main__footer-buttons--mobile-only">
                            <div className="button-outer">
                                <button className="button button--white button--padded" type="submit">
                                    <span className="icon icon__save"></span>
                                    <span className="button-text">{t('Allaround.Button.save')}</span>
                                </button>
                            </div>
                            <div className="button-outer">
                                <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                    <span className="icon icon__delete"></span>
                                    <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </main>
    )
}
