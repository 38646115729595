import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik';
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage';
import { SalePlaceModel } from '../../../shared/models';
import { SalePlacesApi } from '../../../services/SalePlacesApi';
import { useGridActions } from '../../../shared/grid/GridContextProvider';
import * as Yup from 'yup';
import { toastSuccess, toastWarning } from '../../../shared/toastr';
import { Overlay } from '../../../shared/elements/Overlay';
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation';

export const SalePlaceForm = ({ closeForm, initialValues, onSalePlaceUpserted }: { onSalePlaceUpserted: (canCreateNextSalePalce: boolean) => void, initialValues: SalePlaceModel | null, closeForm: () => void }) => {

    const { t } = useOfferionTranslation();
    const gridActions = useGridActions();

    const upsertSalePlace = async (salePlace: SalePlaceModel) => {
        console.log("submitting form")
        const result = await SalePlacesApi.upsert(salePlace);

        if (!result.salePlaceCreated)
            toastWarning('Settings.SalesPlaces.Header.NewSalesPlace.warningMessage')
        else {
            toastSuccess('Settings.SalesPlaces.Edit.Message.SuccessUpdate')

            gridActions.refresh();

            closeForm();
        }

        onSalePlaceUpserted(result.canCreateNextSalePlace)
    }

    const validationSchema = Yup.object()
        .shape({
            displayName: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.name") })),
            externalId: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.salesPlaceLabel") })),
            externalSubId: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.billingDeviceLabel") })),
            addressStreet: Yup
                .string()
                .nullable()
                .when('salePlaceHasAddress', (hasAddress: boolean, schema: Yup.StringSchema) => {

                    if (hasAddress)
                        return schema.required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.street") }));

                    return schema.notRequired()

                }),
            addressNumber: Yup
                .string()
                .nullable()
                .when('salePlaceHasAddress', (hasAddress: boolean, schema: Yup.StringSchema) => {

                    if (hasAddress)
                        return schema.required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.streetNumber") }))
                            .max(10, t("Allaround.Validation.maxLength", { fieldName: t("Settings.SalesPlaces.Edit.Table.streetNumber"), maxLength: 10 }));

                    return schema.notRequired()

                }),
            addressCity: Yup
                .string()
                .nullable()
                .when('salePlaceHasAddress', (hasAddress: boolean, schema: Yup.StringSchema) => {

                    if (hasAddress)
                        return schema.required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.city") }));

                    return schema.notRequired()

                }),
            addressZipCode: Yup
                .string()
                .nullable()
                .when('salePlaceHasAddress', (hasAddress: boolean, schema: Yup.StringSchema) => {

                    if (hasAddress)
                        return schema.required(t("Allaround.Validation.required", { fieldName: t("Settings.SalesPlaces.Edit.Table.zipCode") }))
                            .max(10);

                    return schema.notRequired()

                })
        });

    return <Formik validationSchema={validationSchema} initialValues={initialValues
        ? { ...initialValues, salePlaceHasAddress: !!initialValues.addressStreet }
        : {
            addressArea: "",
            addressCity: "",
            addressDescription: "",
            addressNumber: "",
            addressStreet: "",
            addressZipCode: "",
            displayName: "",
            externalId: "",
            externalSubId: "",
            id: 0,
            salePlaceHasAddress: false
        }} onSubmit={upsertSalePlace}>
        {
            formikProps => <Overlay>
                <Form>
                    <div>
                        <h1 className="overlay__title">
                            {initialValues ? t('Settings.SalesPlaces.Edit.Header.pageTitleEdit') : t('Settings.SalesPlaces.Edit.Header.pageTitleNew')}
                        </h1>

                        <button type="button" className="button overlay__close" onClick={closeForm}>
                            <span className="icono-cross"></span>
                        </button>

                        <div className="info__container">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="salePlaceName">{t('Settings.SalesPlaces.Edit.Table.name')}</label>
                                <div className="info__row-content">
                                    <Field autoFocus as="textarea" id="salePlaceName" name="displayName" rows={2} placeholder={t('Settings.SalesPlaces.Edit.Table.namePlaceholder')} />
                                    <div className="info__row-content">
                                        <OfferionErrorMessage name="displayName" />
                                    </div>
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="externalId">{t('Settings.SalesPlaces.Edit.Table.salesPlaceLabel')}</label>
                                <div className="info__row-content">
                                    <Field id="externalId" type="text" name="externalId" placeholder={t('Settings.SalesPlaces.Edit.Table.salesPlaceLabelPlaceholder')} />
                                </div>
                                <div className="info__row-content">
                                    <OfferionErrorMessage name="externalId" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="externalSubId">{t('Settings.SalesPlaces.Edit.Table.billingDeviceLabel')}</label>
                                <div className="info__row-content">
                                    <Field id="externalSubId" type="text" name="externalSubId" placeholder={t('Settings.SalesPlaces.Edit.Table.billingDeviceLabelPlaceholder')} />
                                </div>
                                <div className="info__row-content">
                                    <OfferionErrorMessage name="externalSubId" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="insertInvoicePrefix">{t('Settings.SalesPlaces.Edit.Table.invoiceSufix')}</label>
                                <div className="info__row-content">
                                    <Field id="insertInvoicePrefix" type="text" name="invoiceSufix" placeholder={t('Settings.SalesPlaces.Edit.Table.invoiceSufixPlaceholder')} />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="invoiceNumberSeparator">{t('Settings.SalesPlaces.Edit.Table.invoiceSeparator')}</label>
                                <div className="info__row-content">
                                    <Field id="invoiceNumberSeparator" type="text" name="invoiceNumberSeparator" placeholder={t('Settings.SalesPlaces.Edit.Table.invoiceSeparatorPlaceholder')} />
                                </div>
                            </div>

                            <div className="info__row" style={{ display: "flex", alignItems: "center" }} >
                                <label className="info__row-content" htmlFor="salePlaceHasAddress" style={{ flexGrow: 1 }}
                                >{t('Settings.SalesPlaces.Edit.Table.salesPlaceHaveAddressYesNo')}</label>
                                <div className="info__row-small" style={{ marginRight: "15px" }} >
                                    <Field id="salePlaceHasAddress" type="checkbox" className="checkbox" name="salePlaceHasAddress" onChange={(e: any) => {

                                        formikProps.setValues({
                                            ...formikProps.values,
                                            salePlaceHasAddress: !formikProps.values.salePlaceHasAddress,

                                            addressArea: "",
                                            addressCity: "",
                                            addressDescription: "",
                                            addressZipCode: "",
                                            addressNumber: "",
                                            addressStreet: ""
                                        })
                                    }}
                                    />
                                    <label htmlFor="salePlaceHasAddress" className="button button--gray checkbox-button right">
                                        <span className="checkbox-button__icon"></span>
                                    </label>
                                </div>
                            </div>

                            {formikProps.values.salePlaceHasAddress ?
                                <>
                                    <div className="info__row" >
                                        <label className="info__row-name" htmlFor="insertStreet">{t('Settings.SalesPlaces.Edit.Table.street')}</label>
                                        <div className="info__row-content">
                                            <Field id="insertStreet" type="text" name="addressStreet" placeholder={t('Settings.SalesPlaces.Edit.Table.streetPlaceholder')} />
                                        </div>
                                        <div className="info__row-content">
                                            <OfferionErrorMessage name="addressStreet" />
                                        </div>
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="insertSalePlaceStreetNumber">{t('Settings.SalesPlaces.Edit.Table.streetNumber')}</label>
                                        <div className="info__row-content">
                                            <Field id="insertSalePlaceStreetNumber" type="text" name="addressNumber" placeholder={t('Settings.SalesPlaces.Edit.Table.streetNumberPlaceholder')} />
                                        </div>
                                        <div className="info__row-content">
                                            <OfferionErrorMessage name="addressNumber" />
                                        </div>
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="insertSalePlaceCity">{t('Settings.SalesPlaces.Edit.Table.city')}</label>
                                        <div className="info__row-content">
                                            <Field id="insertSalePlaceCity" type="text" name="addressCity" placeholder={t('Settings.SalesPlaces.Edit.Table.cityPlaceholder')} />
                                        </div>
                                        <div className="info__row-content">
                                            <OfferionErrorMessage name="addressCity" />
                                        </div>
                                    </div>

                                    <div className="info__row" >
                                        <label className="info__row-name" htmlFor="insertSalePostalCode">{t('Settings.SalesPlaces.Edit.Table.zipCode')}</label>
                                        <div className="info__row-content">
                                            <Field id="insertSalePostalCode" type="text" name="addressZipCode" placeholder={t('Settings.SalesPlaces.Edit.Table.zipCodePlaceholder')} />
                                        </div>
                                        <div className="info__row-content">
                                            <OfferionErrorMessage name="addressZipCode" />
                                        </div>
                                    </div>

                                    <div className="info__row" >
                                        <label className="info__row-name" htmlFor="insertSalePlaceCounty">{t('Settings.SalesPlaces.Edit.Table.county')}</label>
                                        <div className="info__row-content">
                                            <Field id="insertSalePlaceCounty" type="text" name="addressArea" placeholder={t('Settings.SalesPlaces.Edit.Table.countyPlaceholder')} />
                                        </div>
                                    </div>
                                </> : <div className="info__row">
                                    <label className="info__row-name" htmlFor="insertAddressDescription">{t('Settings.SalesPlaces.Edit.Table.description')}</label>
                                    <div className="info__row-content">
                                        <Field as="textarea" id="insertAddressDescription" name="addressDescription" rows={2} placeholder={t('Settings.SalesPlaces.Edit.Table.descriptionPlaceholder')} />
                                    </div>
                                </div>}


                        </div>

                        <div className="button-group bottom-controls">
                            <button className="button button--gray button--padded" type="submit" disabled={formikProps.isSubmitting}>
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                            </button>
                            <button className="button button--gray button--padded" type="button" onClick={closeForm}>
                                <span className="icon icon--small icon__cross-red"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                            </button>
                        </div>
                        <br />
                    </div>
                </Form>
            </Overlay>
        }
    </Formik >

}

export const AddSalePlaceButton = ({ customOnClick, onSalePlaceUpserted }: { onSalePlaceUpserted: (canCreateNextSalePalce: boolean) => void, customOnClick: (() => void) | null }) => {
    const { t } = useOfferionTranslation();
    const [isOverlayHidden, setIsOverlayHidden] = useState(true);

    return isOverlayHidden
        ? <div className="table__button-container">
            <button className="button button--white table__button" onClick={() => customOnClick ? customOnClick() : setIsOverlayHidden(false)}>
                <span className="icon icon__plus"></span>
            </button>
            <span className="button-text table__button-text mod-margin-left-4">{t('Settings.SalesPlaces.Header.newSalesPlace')}</span>
        </div> : <div className="button-container small-hide overlay__item overlay__item--bottom-left overlay__item--active overlay__item--show-overlay" >
            <button className="button button--gray button--plus overlay__button overlay__button--active">
                <span className="icon icon__plus"></span>
            </button>

            <SalePlaceForm onSalePlaceUpserted={onSalePlaceUpserted} initialValues={null} closeForm={() => setIsOverlayHidden(true)} />

            <span className="button-text table__button-text mod-margin-left-4">{t('Settings.SalesPlaces.Header.newSalesPlace')}</span>
        </div >

}